<template>
  <transition name="zoom">
    <div
      v-if="alertOpen"
      class="fixed z-[5000] top-0 left-0 w-full h-full flex items-center justify-center isolate overscroll-contain"
    >
      <div
        class="absolute top-0 left-0 w-full h-full bg-transparent z-0 backdrop-blur-md"
        @click="close"
      />
      <div
        class="z-10 w-full max-w-xl bg-white rounded-md shadow-md max-h-[90vh] overflow-y-auto flex flex-col relative isolate p-4 gap-4"
      >
        <JoszakiHeading :level="2">
          {{ actualTitle }}
        </JoszakiHeading>
        <div class="flex items-center gap-4">
          <div class="text-4xl" :class="{ [`text-${type}`]: type }">
            <IconComponent :icon="icon" />
          </div>
          {{ message }}
        </div>
        <div class="flex items-center justify-center">
          <JoszakiButton :type="type" :expanded="true" @click="close">
            {{ $t("common.accept") }}
          </JoszakiButton>
        </div>
      </div>
    </div>
  </transition>
</template>
<script>
import { JOSZAKI_ALERT_EVENT } from "~/plugins/joszaki";

export default {
  data() {
    return {
      alertOpen: false,
      message: "Test message",
      title: "Test title",
      type: "info",
    };
  },
  computed: {
    icon() {
      if (!this.type) {
        return undefined;
      }
      switch (this.type) {
        case "success":
          return "check-circle";
        case "warning":
          return "exclamation-triangle";
        case "error":
        case "danger":
          return "times-circle";
        default:
          return "info-circle";
      }
    },
    actualTitle() {
      if (this.title) {
        return this.title;
      }

      if (!this.type) {
        return this.$t("alertDialog.title.info");
      }

      return this.$t(`alertDialog.title.${this.type}`);
    },
  },
  mounted() {
    this.$mitt.on(JOSZAKI_ALERT_EVENT, this.show);
  },
  beforeDestroy() {
    this.$mitt.off(JOSZAKI_ALERT_EVENT, this.show);
  },
  methods: {
    show({ title, message, type }) {
      if (
        ![
          undefined,
          null,
          "success",
          "info",
          "danger",
          "warning",
          "error",
        ].includes(type)
      ) {
        throw new Error(`received invalid type: ${type}`);
      }
      this.alertOpen = true;
      this.message = message;
      this.title = title;
      this.type = type ?? "info";
    },
    close() {
      this.alertOpen = false;
    },
  },
};
</script>

<style lang="scss" scoped>
.zoom-enter-active,
.zoom-leave-active {
  transition: all 0.2s ease;
}
.zoom-enter,
.zoom-leave-to {
  transform: scale(0, 0);
}
</style>
