<template>
  <transition-group
    name="toast-container"
    tag="div"
    class="JoszakiToastContainer flex flex-col justify-center items-center gap-2 w-max max-w-[90vw] sm:max-w-[80vw] md:max-w-[70vw] lg:max-w-[60vw] top-2 absolute left-1/2 -translate-x-1/2 z-[1001]"
  >
    <JoszakiToast
      v-for="toast in toasts"
      :key="toast.id"
      :type="toast.type"
      :message="toast.message"
    />
  </transition-group>
</template>

<script>
import JoszakiToast from "./JoszakiToast.vue";
import { JOSZAKI_TOAST_EVENT } from "~/plugins/joszaki";

export default {
  components: { JoszakiToast },
  data() {
    return {
      toasts: [],
    };
  },
  mounted() {
    this.$mitt.on(JOSZAKI_TOAST_EVENT, this.addToast);
  },
  beforeDestroy() {
    this.$mitt.off(JOSZAKI_TOAST_EVENT, this.addToast);
  },
  methods: {
    addToast(toast) {
      this.toasts.push(toast);
      setTimeout(() => {
        this.toasts = this.toasts.filter((t) => t.id !== toast.id);
      }, 5000);
    },
  },
};
</script>

<style lang="scss" scoped>
.toast-container-move,
.toast-container-enter-active,
.toast-container-leave-active {
  transition: all 0.2s ease;
}
.toast-container-enter,
.toast-container-leave-to {
  transform: scale(0, 0);
}
</style>
